import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon, CopyIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Hosts, Urls
} from "../../../constants";
import { mergeUrl, getUrlParams } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { getRagChatbotIframeUrl, selectRagChatbot } from '../../../store/models/Ai';
import cx from 'classnames';
import Loader from '../../../components/Loader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import { KnowledgeTab } from '../../../components/rag-chatbot/KnowledgeTab';
import { RagSettingsTab } from '../../../components/rag-chatbot/RagSettingsTab';
import { SelectInput } from '../../../components/form/SelectInput';
import { selectMobileLayout } from '../../../store/UIState';
import { selectCurrentProjectId } from '../../../store/models/Project';
import { replaceTo, pushTo } from '../../../store/history';
import { RagIntegrationTab } from '../../../components/rag-chatbot/RagIntegratrionTab';
import { DailyChart } from '../../../components/Chart';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Pagination } from '../../../components/Pagination';
import { getDatesBetween } from '../../../utils';
import classNames from 'classnames';
import { RagStatsTab } from '../../../components/rag-chatbot/RagStatsTab';
function RagChatbotDetailPage() {
  const dispatch = useDispatch();
  const { projectId, id, selectedSubtab } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [selectedSubTab, setSelectedSubTab] = useState(selectedSubtab || 'playground');
  const [accessToken, setAccessToken] = useState();

  const chatbot = useSelector(state => selectRagChatbot(state, projectId, id));
  const isMobile = useSelector(state => selectMobileLayout(state));
  const stateProjectId = useSelector(state => selectCurrentProjectId(state));

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [projectId, id]);

  useEffect(() => {
    if (stateProjectId !== projectId) {
      replaceTo(mergeUrl(Urls.AI_RAG_CHATBOT, { projectId }));
    }
  }, [stateProjectId]);

  useEffect(() => {
    if (selectedSubtab !== selectedSubTab) {
      setSelectedSubTab(selectedSubtab || 'playground');
    }
  }, [selectedSubtab]);

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
      let res = await dispatch(Ai.actions.fetchRagAccessToken(projectId, id));
      setAccessToken(res);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const onTabChanged = (tab) => {
    pushTo(mergeUrl(Urls.AI_CHATBOT_DETAIL_TAB, {
      selectedTab: 'rag-chatbot-detail',
      projectId,
      id,
      selectedSubtab: tab
    }));
    setSelectedSubTab(tab);
  }

  const tabOptions = [
    { label: 'Playground', value: 'playground' },
    { label: 'Integration', value: 'integration' },
    { label: 'Settings', value: 'settings' },
    { label: 'Knowledge base', value: 'knowledge-base' },
    { label: 'Stats', value: 'stats' },
  ];

  return (<div className={'AiServicePage AiServicePage--with-back-button'}>
    <Link to={mergeUrl(Urls.AI_RAG_CHATBOT, { projectId })}>
      <div className={'AiServicePage__back-button rag-chatbot'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          RAG Chatbot
        </div>
      </div>
    </Link>
    {!loading && chatbot && <div className={'AiServicePage__header detail'}>
      <div className={'AiServicePage__header--title'}>{chatbot.name || 'RAG Chatbot'}</div>
    </div>}
    {!loading && chatbot && <div className={'AiServicePage__content new-deployment'}>
      {isMobile ? (
        <SelectInput
          value={{ label: tabOptions.find(t => t.value === selectedSubTab).label, value: selectedSubTab }}
          options={tabOptions}
          styles={selectStyles}
          onChange={(option) => onTabChanged(option.value)}
        />
      ) : (
        <AiTabs>
          <AiTab isSelected={(selectedSubTab === 'playground')}
            onClick={() => onTabChanged('playground')}>
            Playground
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'integration')}
            onClick={() => onTabChanged('integration')}>
            Integration
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'settings')}
            onClick={() => onTabChanged('settings')}>
            Settings
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'knowledge-base')}
            onClick={() => onTabChanged('knowledge-base')}>
            Knowledge base
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'stats')}
            onClick={() => onTabChanged('stats')}>
            Stats
          </AiTab>
        </AiTabs>
      )}
      {
        (selectedSubTab === 'playground') && chatbot &&
        <PlaygroundTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'integration') && chatbot &&
        <RagIntegrationTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'settings') && chatbot &&
        <RagSettingsTab chatbot={chatbot} projectId={projectId} accessToken={accessToken} setAccessToken={setAccessToken} />
      }
      {
        (selectedSubTab === 'knowledge-base') && chatbot &&
        <KnowledgeTab chatbot={chatbot} />
      }
      {
        (selectedSubTab === 'stats') && chatbot &&
        <RagStatsTab chatbot={chatbot} />
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !chatbot && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

const PlaygroundTab = (props) => {
  const { chatbot, accessToken } = props;

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const iframeUrl = useMemo(() =>
    getRagChatbotIframeUrl(chatbot, accessToken), [chatbot]);

  const onCopy = () => {
    navigator.clipboard.writeText(iframeUrl)
      .then(() => {
        toast.success('Chatbot URL Copied!')
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }
  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage playground'>
      <div className='RagChatbotDetailPage__row'>

        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label-row'>
            <div className='RagChatbotDetailPage__input-label'>
              Chatbot URL <div className='RagChatbotDetailPage__input-label--icon' onClick={onCopy}><CopyIcon /></div>
            </div>
            <Button href={iframeUrl} target='_blank' title={"Open"} size={'small'} color={'green-outline'} />
          </div>

          <div className='RagChatbotDetailPage__input-div'>
            <div className='RagChatbotDetailPage__input-div--info RagChatbotDetailPage__input-div--one-line'>{iframeUrl}</div>
          </div>
        </div>
      </div>
      <div className='RagChatbotDetailPage__playground'>
        <iframe src={iframeUrl} width='100%' height='100%' />
      </div>
    </div>
  </div >
}


export default RagChatbotDetailPage;

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#202432',
    borderColor: 'transparent',
    borderRadius: '4px',
    color: '#8A8FB5',
    outline: 'none',
    borderRadius: '0.8rem',
    minHeight: 'none',
    height: '50px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#18C99D', // Add your hover styles here
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#202432' :
      state.isFocused ? '#1b2b32' : '#202432',
    color: state.isDisabled ? '#FFFFFF' :
      state.isFocused ? '#18C99D' :
        state.isSelected ? '#18C99D' : '#8A8FB5',
    fontFamily: 'Gilroy',
    paddingLeft: '10px',
    fontWeight: '500',
    fontSize: '14px',
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
    height: 'auto',
    position: 'relative',
    cursor: state.isDisabled ? 'default' : 'pointer',
    ':hover': {
      backgroundColor: state.isDisabled ? '#202432' : '#1b2b32',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    borderColor: state.isSelected ? '#18C99D' : 'transparent',
    color: '#8A8FB5',
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontSize: '14px',
    outline: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#8A8FB5',
    marginRight: '0',
    marginLeft: '4px',
    ':hover': {
      color: '#8A8FB5', // Add your hover styles here
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    // border: '1px solid #3D4463',
    borderRadius: '4px',
    scrollbarColor: '#3D4463',
    width: '100%',
    height: '4rem',
    zIndex: 30
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '500px',
  }),
}
